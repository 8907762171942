<template>
  <div class="container">
    <el-breadcrumb separator-class="el-icon-arrow-right" style="	height: 40px;">
      <el-breadcrumb-item>{{ fun.getLanguageText($store.state.language, '设备状态') }}</el-breadcrumb-item>
    </el-breadcrumb>
    <el-tabs v-model="activeName" class="tabs">
      <el-tab-pane v-loading="loading" :style="'overflow-y: scroll; height: ' + $store.state.clientHeight + 'px;'"
        :label="fun.getLanguageText($store.state.language, '实时状态')" name="first">

        <div class="showImg" style="padding: 0 24px 24px;">
          <div style="width: 193px; border: 1px solid #337c95; padding: 12px;">
            <div style="text-align: center;font-size: 20px;margin-bottom: 12px">{{
              fun.getLanguageText($store.state.language, '通道一') }}</div>
            <div style="display: flex;align-items: center;">
              <div style="height: 24px;line-height: 24px;">{{ fun.getLanguageText($store.state.language, '配方') }}:</div>
              <div style="width: 130px;height: 24px;line-height: 24px;margin-left: 12px;color: #1A74D2">
                {{ channel_state['1'].recipe ? channel_state['1'].recipe : fun.getLanguageText($store.state.language,
                  '无配方') }}
              </div>
            </div>
            <div style="width: 100%;display: flex;justify-content: space-between;margin-top: 15px">
              <div style="display: flex;flex-direction: column;align-items: center">
                <img v-if="stat.L_CH1 === '1'" src="@/assets/img1/ben2.png" style="width: 36px;height: 36px;" />
                <img v-if="stat.L_CH1 === '0'" src="@/assets/img1/ben1.png" style="width: 36px;height: 36px;" />
                {{ fun.getLanguageText($store.state.language, '泵') }}
              </div>
              <div style="display: flex;flex-direction: column;align-items: center">
                <img v-if="stat.L_CH1 === '1'" src="@/assets/img1/ben.gif" style="width: 55px;height: 65px;" />
                <img v-if="stat.L_CH1 === '0'" src="@/assets/img1/ben.png" style="width: 55px;height: 65px;" />
              </div>
              <div style="display: flex;flex-direction: column;align-items: center">
                <img v-if="stat.L_A1 === '1'" src="@/assets/img1/qi1.gif" style="width: 31px;height: 60px;" />
                <img v-if="stat.L_A1 === '0'" src="@/assets/img1/qi0.png" style="width: 31px;height: 60px;" />
                {{ fun.getLanguageText($store.state.language, '气冲') }}
              </div>

            </div>
            <div style="width: 100%;display: flex;justify-content: space-between;margin-top: 10px;align-items: end">
              <div
                style="display: flex;flex-direction: column;align-items: center;height: 90px;justify-content: space-between">
                <img v-if="stat.L_W1 === '1'" src="@/assets/img1/shui1.gif" style="width: 31px;height: 71px;" />
                <img v-if="stat.L_W1 === '0'" src="@/assets/img1/shui0.png" style="width: 31px;height: 71px;" />
                {{ fun.getLanguageText($store.state.language, '水冲') }}
              </div>
              <div
                style="display: flex;flex-direction: column;align-items: center;height: 90px;justify-content: space-between">
                <img v-if="stat.L_C1 === '1'" src="@/assets/img1/ye1.gif" style="width: 31px;height: 60px;" />
                <img v-if="stat.L_C1 === '0'" src="@/assets/img1/ye0.png" style="width: 31px;height: 60px;" />
                {{ channel_state['1'].medicamentName['1'] }}
              </div>
              <div
                style="display: flex;flex-direction: column;align-items: center;height: 90px;justify-content: space-between">
                <img v-if="stat.L_C2 === '1'" src="@/assets/img1/ye1.gif" style="width: 31px;height: 60px;" />
                <img v-if="stat.L_C2 === '0'" src="@/assets/img1/ye0.png" style="width: 31px;height: 60px;" />
                {{ channel_state['1'].medicamentName['2'] }}
              </div>
              <div
                style="display: flex;flex-direction: column;align-items: center;height: 90px;justify-content: space-between">
                <img v-if="stat.L_C3 === '1'" src="@/assets/img1/ye1.gif" style="width: 31px;height: 60px;" />
                <img v-if="stat.L_C3 === '0'" src="@/assets/img1/ye0.png" style="width: 31px;height: 60px;" />
                {{ channel_state['1'].medicamentName['3'] }}
              </div>
            </div>
            <div style="width: 100%;display: flex;justify-content: space-between;margin-top: 12px;">
              <div v-if="stat.L_S1 === '0'"
                style="width: 46px;height: 18px;line-height: 18px;text-align: center;background: #c1c8cb;border-radius: 2px;">
                {{ fun.getLanguageText($store.state.language, '信号') }}1
              </div>
              <div v-if="stat.L_S1 === '1'"
                style="color: #FFFFFF;width: 46px;height: 18px;line-height: 18px;text-align: center;background: #11A23A;border-radius: 2px;">
                {{ fun.getLanguageText($store.state.language, '信号') }}1
              </div>
              <div v-if="stat.L_S2 === '0'"
                style="width: 46px;height: 18px;line-height: 18px;text-align: center;background: #c1c8cb;border-radius: 2px;">
                {{ fun.getLanguageText($store.state.language, '信号') }}2
              </div>
              <div v-if="stat.L_S2 === '1'"
                style="color: #FFFFFF;width: 46px;height: 18px;line-height: 18px;text-align: center;background: #11A23A;border-radius: 2px;">
                {{ fun.getLanguageText($store.state.language, '信号') }}2
              </div>
              <div v-if="stat.L_S3 === '0'"
                style="width: 46px;height: 18px;line-height: 18px;text-align: center;background: #c1c8cb;border-radius: 2px;">
                {{ fun.getLanguageText($store.state.language, '信号') }}3
              </div>
              <div v-if="stat.L_S3 === '1'"
                style="color: #FFFFFF;width: 46px;height: 18px;line-height: 18px;text-align: center;background: #11A23A;border-radius: 2px;">
                {{ fun.getLanguageText($store.state.language, '信号') }}3
              </div>
            </div>
          </div>
          <div style="width: 193px; border: 1px solid #337c95;  ;padding: 12px">
            <div style="text-align: center;font-size: 20px;margin-bottom: 12px">{{
              fun.getLanguageText($store.state.language, '通道二') }}</div>
            <div style="display: flex;align-items: center;">
              <div style="height: 24px;line-height: 24px;">{{ fun.getLanguageText($store.state.language, '配方') }}:
              </div>
              <div style="width: 130px;height: 24px;line-height: 24px;margin-left: 12px;color: #1A74D2">
                {{ channel_state['2'].recipe ? channel_state[2].recipe : fun.getLanguageText($store.state.language,
                  '无配方') }}
              </div>
            </div>
            <div style="width: 100%;display: flex;justify-content: space-between;margin-top: 15px">
              <div style="display: flex;flex-direction: column;align-items: center">
                <img v-if="stat.L_CH2 === '1'" src="@/assets/img1/ben2.png" style="width: 36px;height: 36px;" />
                <img v-if="stat.L_CH2 === '0'" src="@/assets/img1/ben1.png" style="width: 36px;height: 36px;" />
                {{ fun.getLanguageText($store.state.language, '泵') }}
              </div>
              <div style="display: flex;flex-direction: column;align-items: center">
                <img v-if="stat.L_CH2 === '1'" src="@/assets/img1/ben.gif" style="width: 55px;height: 65px;" />
                <img v-if="stat.L_CH2 === '0'" src="@/assets/img1/ben.png" style="width: 55px;height: 65px;" />
              </div>
              <div style="display: flex;flex-direction: column;align-items: center">
                <img v-if="stat.L_A2 === '1'" src="@/assets/img1/qi1.gif" style="width: 31px;height: 60px;" />
                <img v-if="stat.L_A2 === '0'" src="@/assets/img1/qi0.png" style="width: 31px;height: 60px;" />
                {{ fun.getLanguageText($store.state.language, '气冲') }}
              </div>

            </div>
            <div style="width: 100%;display: flex;justify-content: space-between;margin-top: 10px;align-items: end">
              <div
                style="display: flex;flex-direction: column;align-items: center;height: 90px;justify-content: space-between">
                <img v-if="stat.L_W2 === '1'" src="@/assets/img1/shui1.gif" style="width: 31px;height: 71px;" />
                <img v-if="stat.L_W2 === '0'" src="@/assets/img1/shui0.png" style="width: 31px;height: 71px;" />
                {{ fun.getLanguageText($store.state.language, '水冲') }}
              </div>
              <div
                style="display: flex;flex-direction: column;align-items: center;height: 90px;justify-content: space-between">
                <img v-if="stat.L_C4 === '1'" src="@/assets/img1/ye1.gif" style="width: 31px;height: 60px;" />
                <img v-if="stat.L_C4 === '0'" src="@/assets/img1/ye0.png" style="width: 31px;height: 60px;" />
                {{ channel_state['2'].medicamentName['4'] }}
              </div>
              <div
                style="display: flex;flex-direction: column;align-items: center;height: 90px;justify-content: space-between">
                <img v-if="stat.L_C5 === '1'" src="@/assets/img1/ye1.gif" style="width: 31px;height: 60px;" />
                <img v-if="stat.L_C5 === '0'" src="@/assets/img1/ye0.png" style="width: 31px;height: 60px;" />
                {{ channel_state['2'].medicamentName['5'] }}
              </div>
              <div
                style="display: flex;flex-direction: column;align-items: center;height: 90px;justify-content: space-between">
                <img v-if="stat.L_C6 === '1'" src="@/assets/img1/ye1.gif" style="width: 31px;height: 60px;" />
                <img v-if="stat.L_C6 === '0'" src="@/assets/img1/ye0.png" style="width: 31px;height: 60px;" />
                {{ channel_state['2'].medicamentName['6'] }}
              </div>
            </div>
            <div style="width: 100%;display: flex;justify-content: space-between;margin-top: 12px;">
              <div v-if="stat.L_S4 === '0'"
                style="width: 46px;height: 18px;line-height: 18px;text-align: center;background: #c1c8cb;border-radius: 2px;">
                {{ fun.getLanguageText($store.state.language, '信号') }}4
              </div>
              <div v-if="stat.L_S4 === '1'"
                style="color: #FFFFFF;width: 46px;height: 18px;line-height: 18px;text-align: center;background: #11A23A;border-radius: 2px;">
                {{ fun.getLanguageText($store.state.language, '信号') }}4
              </div>
              <div v-if="stat.L_S5 === '0'"
                style="width: 46px;height: 18px;line-height: 18px;text-align: center;background: #c1c8cb;border-radius: 2px;">
                {{ fun.getLanguageText($store.state.language, '信号') }}5
              </div>
              <div v-if="stat.L_S5 === '1'"
                style="color: #FFFFFF;width: 46px;height: 18px;line-height: 18px;text-align: center;background: #11A23A;border-radius: 2px;">
                {{ fun.getLanguageText($store.state.language, '信号') }}5
              </div>
              <div v-if="stat.L_S6 === '0'"
                style="width: 46px;height: 18px;line-height: 18px;text-align: center;background: #c1c8cb;border-radius: 2px;">
                {{ fun.getLanguageText($store.state.language, '信号') }}6
              </div>
              <div v-if="stat.L_S6 === '1'"
                style="color: #FFFFFF;width: 46px;height: 18px;line-height: 18px;text-align: center;background: #11A23A;border-radius: 2px;">
                {{ fun.getLanguageText($store.state.language, '信号') }}6
              </div>
            </div>
          </div>
          <div style="width: 193px; border: 1px solid #337c95;  ;padding: 12px">
            <div style="text-align: center;font-size: 20px;margin-bottom: 12px">{{
              fun.getLanguageText($store.state.language, '通道三') }}</div>
            <div style="display: flex;align-items: center;">
              <div style="height: 24px;line-height: 24px;"> {{ fun.getLanguageText($store.state.language, '配方') }}:
              </div>
              <div style="width: 130px;height: 24px;line-height: 24px;margin-left: 12px;color: #1A74D2">
                {{ channel_state['3'].recipe ? channel_state[3].recipe : fun.getLanguageText($store.state.language,
                  '无配方') }}
              </div>
            </div>
            <div style="width: 100%;display: flex;justify-content: space-between;margin-top: 15px">
              <div style="display: flex;flex-direction: column;align-items: center">
                <img v-if="stat.L_CH3 === '1'" src="@/assets/img1/ben2.png" style="width: 36px;height: 36px;" />
                <img v-if="stat.L_CH3 === '0'" src="@/assets/img1/ben1.png" style="width: 36px;height: 36px;" />
                {{ fun.getLanguageText($store.state.language, '泵') }}
              </div>
              <div style="display: flex;flex-direction: column;align-items: center">
                <img v-if="stat.L_CH3 === '1'" src="@/assets/img1/ben.gif" style="width: 55px;height: 65px;" />
                <img v-if="stat.L_CH3 === '0'" src="@/assets/img1/ben.png" style="width: 55px;height: 65px;" />
              </div>
              <div style="display: flex;flex-direction: column;align-items: center">
                <img v-if="stat.L_A3 === '1'" src="@/assets/img1/qi1.gif" style="width: 31px;height: 60px;" />
                <img v-if="stat.L_A3 === '0'" src="@/assets/img1/qi0.png" style="width: 31px;height: 60px;" />
                {{ fun.getLanguageText($store.state.language, '气冲') }}
              </div>
            </div>
            <div style="width: 100%;display: flex;justify-content: space-between;margin-top: 10px;align-items: end">
              <div
                style="display: flex;flex-direction: column;align-items: center;height: 90px;justify-content: space-between">
                <img v-if="stat.L_W3 === '1'" src="@/assets/img1/shui1.gif" style="width: 31px;height: 71px;" />
                <img v-if="stat.L_W3 === '0'" src="@/assets/img1/shui0.png" style="width: 31px;height: 71px;" />
                {{ fun.getLanguageText($store.state.language, '水冲') }}
              </div>
              <div
                style="display: flex;flex-direction: column;align-items: center;height: 90px;justify-content: space-between">
                <img v-if="stat.L_C7 === '1'" src="@/assets/img1/ye1.gif" style="width: 31px;height: 60px;" />
                <img v-if="stat.L_C7 === '0'" src="@/assets/img1/ye0.png" style="width: 31px;height: 60px;" />
                {{ channel_state['3'].medicamentName['7'] }}
              </div>
              <div
                style="display: flex;flex-direction: column;align-items: center;height: 90px;justify-content: space-between">
                <img v-if="stat.L_C8 === '1'" src="@/assets/img1/ye1.gif" style="width: 31px;height: 60px;" />
                <img v-if="stat.L_C8 === '0'" src="@/assets/img1/ye0.png" style="width: 31px;height: 60px;" />
                {{ channel_state['3'].medicamentName['8'] }}
              </div>
              <div style="width: 31px;height: 60px;"></div>
            </div>
            <div style="width: 100%;display: flex;justify-content: space-between;margin-top: 12px;">
              <div v-if="stat.L_S7 === '0'"
                style="width: 46px;height: 18px;line-height: 18px;text-align: center;background: #c1c8cb;border-radius: 2px;">
                {{ fun.getLanguageText($store.state.language, '信号') }}7
              </div>
              <div v-if="stat.L_S7 === '1'"
                style="color: #FFFFFF;width: 46px;height: 18px;line-height: 18px;text-align: center;background: #11A23A;border-radius: 2px;">
                {{ fun.getLanguageText($store.state.language, '信号') }}7
              </div>
              <div v-if="stat.L_S8 === '0'"
                style="width: 46px;height: 18px;line-height: 18px;text-align: center;background: #c1c8cb;border-radius: 2px;">
                {{ fun.getLanguageText($store.state.language, '信号') }}8
              </div>
              <div v-if="stat.L_S8 === '1'"
                style="color: #FFFFFF;width: 46px;height: 18px;line-height: 18px;text-align: center;background: #11A23A;border-radius: 2px;">
                {{ fun.getLanguageText($store.state.language, '信号') }}8
              </div>
              <div style="width: 46px;height: 18px;">
              </div>
            </div>
          </div>
          <div style="width: 193px; border: 1px solid #337c95;  ;padding: 12px">
            <div style="text-align: center;font-size: 20px;margin-bottom: 12px">{{
              fun.getLanguageText($store.state.language, '通道四') }}</div>
            <div style="display: flex;align-items: center;">
              <div style="height: 24px;line-height: 24px;">{{ fun.getLanguageText($store.state.language, '配方') }}:
              </div>
              <div style="width: 130px;height: 24px;line-height: 24px;margin-left: 12px;color: #1A74D2">
                {{ channel_state['4'].recipe ? channel_state[4].recipe : fun.getLanguageText($store.state.language,
                  '无配方') }}
              </div>
            </div>
            <div style="width: 100%;display: flex;justify-content: space-between;margin-top: 15px">
              <div style="display: flex;flex-direction: column;align-items: center">
                <img v-if="stat.L_CH4 === '1'" src="@/assets/img1/ben2.png" style="width: 36px;height: 36px;" />
                <img v-if="stat.L_CH4 === '0'" src="@/assets/img1/ben1.png" style="width: 36px;height: 36px;" />
                {{ fun.getLanguageText($store.state.language, '泵') }}
              </div>
              <div style="display: flex;flex-direction: column;align-items: center">
                <img v-if="stat.L_CH4 === '1'" src="@/assets/img1/ben.gif" style="width: 55px;height: 65px;" />
                <img v-if="stat.L_CH4 === '0'" src="@/assets/img1/ben.png" style="width: 55px;height: 65px;" />
              </div>
              <div style="display: flex;flex-direction: column;align-items: center">
                <img v-if="stat.L_A4 === '1'" src="@/assets/img1/qi1.gif" style="width: 31px;height: 60px;" />
                <img v-if="stat.L_A4 === '0'" src="@/assets/img1/qi0.png" style="width: 31px;height: 60px;" />
                {{ fun.getLanguageText($store.state.language, '气冲') }}
              </div>
            </div>
            <div style="width: 100%;display: flex;justify-content: space-between;margin-top: 10px;align-items: end;">
              <div
                style="display: flex;flex-direction: column;align-items: center;height: 90px;justify-content: space-between">
                <img v-if="stat.L_W4 === '1'" src="@/assets/img1/shui1.gif" style="width: 31px;height: 71px;" />
                <img v-if="stat.L_W4 === '0'" src="@/assets/img1/shui0.png" style="width: 31px;height: 71px;" />
                {{ fun.getLanguageText($store.state.language, '水冲') }}
              </div>
              <div
                style="display: flex;flex-direction: column;align-items: center ;height: 90px;justify-content: space-between">
                <img v-if="stat.L_C9 === '1'" src="@/assets/img1/ye1.gif" style="width: 31px;height: 60px;" />
                <img v-if="stat.L_C9 === '0'" src="@/assets/img1/ye0.png" style="width: 31px;height: 60px;" />
                {{ channel_state['4'].medicamentName['9'] }}
              </div>
              <div
                style="display: flex;flex-direction: column;align-items: center;height: 90px;justify-content: space-between">
                <img v-if="stat.L_C10 === '1'" src="@/assets/img1/ye1.gif" style="width: 31px;height: 60px;" />
                <img v-if="stat.L_C10 === '0'" src="@/assets/img1/ye0.png" style="width: 31px;height: 60px;" />
                {{ channel_state['4'].medicamentName['10'] }}
              </div>

              <div style="width: 31px;height: 60px;"></div>
            </div>
            <div style="width: 100%;display: flex;justify-content: space-between;margin-top: 12px;">
              <div v-if="stat.L_S9 === '0'"
                style="width: 46px;height: 18px;line-height: 18px;text-align: center;background: #c1c8cb;border-radius: 2px;">
                {{ fun.getLanguageText($store.state.language, '信号') }}9
              </div>
              <div v-if="stat.L_S9 === '1'"
                style="color: #FFFFFF;width: 46px;height: 18px;line-height: 18px;text-align: center;background: #11A23A;border-radius: 2px;">
                {{ fun.getLanguageText($store.state.language, '信号') }}9
              </div>
              <div v-if="stat.L_S10 === '0'"
                style="width: 46px;height: 18px;line-height: 18px;text-align: center;background: #c1c8cb;border-radius: 2px;">
                {{ fun.getLanguageText($store.state.language, '信号') }}10
              </div>
              <div v-if="stat.L_S10 === '1'"
                style="color: #FFFFFF;width: 46px;height: 18px;line-height: 18px;text-align: center;background: #11A23A;border-radius: 2px;">
                {{ fun.getLanguageText($store.state.language, '信号') }}10
              </div>
              <div style="width: 46px;height: 18px;">
              </div>
            </div>
          </div>
        </div>
        <div
          style="display: flex; align-items: center;justify-content: space-between; background-color: #f5f5f5; padding-left: 24px; padding-bottom: 20px;">
          <div style="display: flex;">
            <div class="ben-btn" @click="gotoView('1')">{{ fun.getLanguageText($store.state.language, '参数设定') }}</div>
            <div class="ben-btn" @click="gotoView('2')">{{ fun.getLanguageText($store.state.language, '设备报表') }}</div>
          </div>
          <div style="flex: 1;width: 20px"></div>
          <div style="margin-right: 24px;font-size: 16px">{{ fun.getLanguageText($store.state.language, '当前工作模式') }}:
            <span style="margin-left: 8px;font-weight: bold">{{ fun.getLanguageText($store.state.language, stat.workMode)
            }}</span>
          </div>
          <div style="margin-right: 24px;font-size: 16px">{{ fun.getLanguageText($store.state.language, '无线信号强度') }}
          </div>
          <img v-if="wifi == 0" height="24" src="@/assets/img1/wifi0.png" style="margin-right: 24px;" width="33">
          <img v-if="wifi < 8 && wifi > 0" height="24" src="@/assets/img1/wifi1.png" style="margin-right: 24px;"
            width="33">
          <img v-if="wifi < 16 && wifi >= 8" height="24" src="@/assets/img1/wifi2.png" style="margin-right: 24px;"
            width="33">
          <img v-if="wifi < 24 && wifi >= 16" height="24" src="@/assets/img1/wifi3.png" style="margin-right: 24px;"
            width="33">
          <img v-if="wifi < 31 && wifi >= 24" height="24" src="@/assets/img1/wifi4.png" style="margin-right: 24px;"
            width="33">
          <img v-if="wifi == 31" height="24" src="@/assets/img1/wifi5.png" style="margin-right: 24px;" width="33">
          <div style="display: flex;margin-right: 40px;width: 128px;height: 30px;position: relative">

            <img v-if="stat.remoteStat == 0" class="btn-close" src="@/assets/img1/btn-down.png">
            <img v-if="stat.remoteStat == 1" class="btn-close" src="@/assets/img1/btn-up.png">
            <div v-if="stat.remoteStat == 1"
              style="position: absolute;width: 100%;height: 100%;line-height: 30px;color: #FFFFFF;font-size: 10px;text-align: center"
              @click="remote(0)">{{ fun.getLanguageText($store.state.language, '远程控制已开启') }}
            </div>
            <div v-if="stat.remoteStat == 0"
              style="position: absolute;width: 100%;height: 100%;line-height: 30px;color: #FFFFFF;font-size: 10px;text-align: center"
              @click="remote(1)">{{ fun.getLanguageText($store.state.language, '远程控制已关闭') }}
            </div>
            <!--            <el-button v-if="remote_stat==1" :disabled="stat.online==='0'" class="btn-open" @click="remote(0)">远程控制已开启-->
            <!--            </el-button>-->
            <div v-if="stat.remoteStat == -1" disabled
              style="position: absolute;width: 100%;height: 100%;line-height: 30px;color: #FFFFFF;text-align: center;background-color: #72767b">
              {{ fun.getLanguageText($store.state.language, '远程异常') }}
            </div>
          </div>
        </div>
        <div v-if="channel_state !== []" style="display:flex;flex-wrap: wrap;justify-content: space-between">
          <div class="state-item" style="margin-bottom: 12px">
            <div class="item-title">{{ fun.getLanguageText($store.state.language, '通道一') }}</div>
            <div style="width: 100%;display: flex;height: 94px;align-items: center">

              <div class="item-3">{{ fun.getLanguageText($store.state.language, '配方名称:') }}</br>{{
                channel_state['1'].recipe ? channel_state['1'].recipe : fun.getLanguageText($store.state.language,
                  '无配方')
              }}
              </div>
              <div class="item-3">{{ fun.getLanguageText($store.state.language, '泵状态')
               
              }}
               <br/>
              {{ fun.getLanguageText($store.state.language,
  stat.L_CH1 === '1' ?
    "开启" : "关闭") }}</div>
              <div class="item-3">{{ fun.getLanguageText($store.state.language, '运行状态')
              }}</br>{{ fun.getLanguageText($store.state.language, getRunType(1)) }}
              </div>
            </div>
            <div class="item-2"> {{ fun.getLanguageText($store.state.language, '当前所加药剂') }}: {{
              channel_state['1'].medicamentName['1']
            }}-----{{ channel_state['1'].consume['1'] }}L
            </div>
            <div class="item-2"> {{ fun.getLanguageText($store.state.language, '当前所加药剂') }}: {{
              channel_state['1'].medicamentName['2']
            }}-----{{ channel_state['1'].consume['2'] }}L
            </div>
            <div class="item-2"> {{ fun.getLanguageText($store.state.language, '当前所加药剂') }}: {{
              channel_state['1'].medicamentName['3']
            }}-----{{ channel_state['1'].consume['3'] }}L
            </div>
          </div>
          <div class="state-item" style="margin-bottom: 12px">
            <div class="item-title">{{ fun.getLanguageText($store.state.language, '通道二') }}</div>
            <div style="width: 100%;display: flex;height: 94px;align-items: center">
              <div class="item-3">{{ fun.getLanguageText($store.state.language, '配方名称:') }}</br>{{
                channel_state['2'].recipe ? channel_state['2'].recipe : fun.getLanguageText($store.state.language,
                  '无配方')
              }}
              </div>
              <div class="item-3">{{ fun.getLanguageText($store.state.language, '泵状态') }}
                <br/>
                {{
                fun.getLanguageText($store.state.language,
                  stat.L_CH2 === '1' ?
                    "开启" : "关闭") }} </div>
              <div class="item-3">{{ fun.getLanguageText($store.state.language, '运行状态') }}</br>{{
                fun.getLanguageText($store.state.language, getRunType(2)) }}
              </div>
            </div>
            <div class="item-2"> {{ fun.getLanguageText($store.state.language, '当前所加药剂') }}: {{
              channel_state['2'].medicamentName['4']
            }}-----{{ channel_state['2'].consume['4'] }}L
            </div>
            <div class="item-2"> {{ fun.getLanguageText($store.state.language, '当前所加药剂') }}: {{
              channel_state['2'].medicamentName['5']
            }}-----{{ channel_state['2'].consume['5'] }}L
            </div>
            <div class="item-2">{{ fun.getLanguageText($store.state.language, '当前所加药剂') }} : {{
              channel_state['2'].medicamentName['6']
            }}-----{{ channel_state['2'].consume['6'] }}L
            </div>
          </div>
          <div class="state-item" style="margin-bottom: 12px">
            <div class="item-title">{{ fun.getLanguageText($store.state.language, '通道三') }}</div>
            <div style="width: 100%;display: flex;height: 94px;align-items: center">
              <div class="item-3">{{ fun.getLanguageText($store.state.language, '配方名称:') }}</br>{{
                channel_state['3'].recipe ? channel_state['3'].recipe : fun.getLanguageText($store.state.language,
                  '无配方')
              }}
              </div>
              <div class="item-3">{{ fun.getLanguageText($store.state.language, '泵状态') }}
                <br/>
              {{
                fun.getLanguageText($store.state.language,
                  stat.L_CH3 === '1' ?
                    "开启" : "关闭") }} </div>
              <div class="item-3">{{ fun.getLanguageText($store.state.language, '运行状态') }}</br>{{
                fun.getLanguageText($store.state.language, getRunType(3)) }}
              </div>
            </div>
            <!--            <div class="item-1">所加药剂名称以及名称</div>-->
            <div class="item-2">{{ fun.getLanguageText($store.state.language, '当前所加药剂') }} : {{
              channel_state['3'].medicamentName['7']
            }}-----{{ channel_state['3'].consume['7'] }}L
            </div>
            <div class="item-2"> {{ fun.getLanguageText($store.state.language, '当前所加药剂') }}: {{
              channel_state['3'].medicamentName['8']
            }}-----{{ channel_state['3'].consume['8'] }}L
            </div>
          </div>
          <div class="state-item" style="margin-bottom: 12px">
            <div class="item-title">{{ fun.getLanguageText($store.state.language, '通道四') }}</div>
            <div style="width: 100%;display: flex;height: 94px;align-items: center">
              <div class="item-3">{{ fun.getLanguageText($store.state.language, '配方名称:') }}</br>{{
                channel_state['4'].recipe ? channel_state['4'].recipe : fun.getLanguageText($store.state.language,
                  '无配方')
              }}
              </div>
              <div class="item-3">{{ fun.getLanguageText($store.state.language, '泵状态') }}
                <br/>
                {{
                  fun.getLanguageText($store.state.language,
                    stat.L_CH4 === '1' ?
                      "开启" : "关闭") }} </div>
              <div class="item-3">{{ fun.getLanguageText($store.state.language, '运行状态') }}</br>{{
                fun.getLanguageText($store.state.language, getRunType(4)) }}
              </div>
            </div>
            <!--            <div class="item-1">所加药剂名称以及名称</div>-->
            <div class="item-2">{{ fun.getLanguageText($store.state.language, '当前所加药剂') }} : {{
              channel_state['4'].medicamentName['9']
            }}-----{{ channel_state['4'].consume['9'] }}L
            </div>
            <div class="item-2"> {{ fun.getLanguageText($store.state.language, '当前所加药剂') }}: {{
              channel_state['4'].medicamentName['10']
            }}-----{{ channel_state['4'].consume['10'] }}L
            </div>
          </div>
        </div>
      </el-tab-pane>
      <el-tab-pane :label="fun.getLanguageText($store.state.language, '报警日志')" name="second">

        <div class="top">
          <el-select v-model="warn_info.number" :placeholder="fun.getLanguageText($store.state.language, '请选择端口')">
            <el-option v-for="item in 10" :key="item" :value="item - 1"></el-option>
          </el-select>
          <el-select v-model="warn_info.type" :placeholder="fun.getLanguageText($store.state.language, '请选择报警类型')">
            <el-option :label="fun.getLanguageText($store.state.language, '信号时间过短')" value="1"></el-option>
            <el-option :label="fun.getLanguageText($store.state.language, '信号时间过长')" value="2"></el-option>
            <el-option :label="fun.getLanguageText($store.state.language, '信号间隔过短')" value="3"></el-option>
          </el-select>
          <el-date-picker v-model="time_warn" :picker-options="pickerOptions" align="right" class="time"
            :end-placeholder="fun.getLanguageText($store.state.language, '结束日期')" range-separator="~"
            :start-placeholder="fun.getLanguageText($store.state.language, '开始日期')" style="margin-left: 20px"
            type="daterange" unlink-panels @change="change_warn">
          </el-date-picker>
        </div>
        <el-table :data="warn_list" :max-height="$store.state.clientHeight - 100" stripe style="width: 100%">
          <el-table-column :label="fun.getLanguageText($store.state.language, '序号')" prop="id" width="120">
          </el-table-column>
          <el-table-column :label="fun.getLanguageText($store.state.language, '发生时间')" prop="up_time" width="200">
          </el-table-column>
          <el-table-column :label="fun.getLanguageText($store.state.language, '记录时间')" prop="db_time" width="200">
          </el-table-column>
          <el-table-column :label="fun.getLanguageText($store.state.language, '设备名称')" prop="deviceName" width="160">
          </el-table-column>
          <el-table-column label="IMEI" prop="imei" width="160">
          </el-table-column>
          <el-table-column label="log">
            <template slot-scope="scope">
              <span>{{ fun.getLanguageText($store.state.language, scope.row.msg) }}</span>
            </template>
          </el-table-column>
        </el-table>
        <el-pagination :current-page="warn_page.currentPage" :page-size="warn_page.pageSize"
          :page-sizes="warn_page.pageSizes" :total="warn_page.total" background class="footer"
          layout="sizes, prev, pager, next, jumper, total" @size-change="sizeChangeWarn"
          @current-change="currentChangeWarn">
        </el-pagination>
        <el-button style="position: absolute; right: 0 ;top:  0" @click="download_uu('warn')">{{
          fun.getLanguageText($store.state.language, '下载报警日志') }}</el-button>
      </el-tab-pane>
      <el-tab-pane :label="fun.getLanguageText($store.state.language, '数据记录')" name="third">
        <div class="top">
          <el-select v-model="data_info.channel" :placeholder="fun.getLanguageText($store.state.language, '请选择通道')"
            @change="change_data">
            <el-option :label="fun.getLanguageText($store.state.language, '全部通道')" value="0"></el-option>
            <el-option :label="fun.getLanguageText($store.state.language, '通道一')" value="1"></el-option>
            <el-option :label="fun.getLanguageText($store.state.language, '通道二')" value="2"></el-option>
            <el-option :label="fun.getLanguageText($store.state.language, '通道三')" value="3"></el-option>
            <el-option :label="fun.getLanguageText($store.state.language, '通道四')" value="4"></el-option>
          </el-select>
          <el-select v-model="data_info.number" :placeholder="fun.getLanguageText($store.state.language, '请选择端口')"
            @change="change_data">
            <el-option :label="fun.getLanguageText($store.state.language, '全部端口')" value="0"></el-option>
            <el-option v-for="item in 10" :key="item" :value="item"></el-option>
          </el-select>
          <el-select v-model="data_info.type" :placeholder="fun.getLanguageText($store.state.language, '请选择阀门类型')"
            @change="change_data">
            <el-option :label="fun.getLanguageText($store.state.language, '全部类型')" value="0"></el-option>
            <el-option :label="fun.getLanguageText($store.state.language, '泵')" value="1"></el-option>
            <el-option :label="fun.getLanguageText($store.state.language, '液')" value="2"></el-option>
            <el-option :label="fun.getLanguageText($store.state.language, '水')" value="3"></el-option>
            <el-option :label="fun.getLanguageText($store.state.language, '气')" value="4"></el-option>
            <!--            <el-option label="进入时间控制" value="a"></el-option>-->
            <!--            <el-option label="推出时间控制" value="b"></el-option>-->
            <!--            <el-option label="进入编程控制" value="c"></el-option>-->
            <!--            <el-option label="退出编程控制" value="d"></el-option>-->
            <!--            <el-option label="编号泵阀打开" value="1"></el-option>-->
            <!--            <el-option label="泵阀打开" value="2"></el-option>-->
            <!--            <el-option label="液阀打开" value="3"></el-option>-->
            <!--            <el-option label="液阀打开" value="4"></el-option>-->
            <!--            <el-option label="水阀打开" value="5"></el-option>-->
            <!--            <el-option label="水阀打开" value="6"></el-option>-->
            <!--            <el-option label="气阀打开" value="7"></el-option>-->
            <!--            <el-option label="气阀打开" value="8"></el-option>-->
          </el-select>
          <el-date-picker v-model="time_data" :picker-options="pickerOptions" align="right" class="time"
            :end-placeholder="fun.getLanguageText($store.state.language, '结束日期')" range-separator="~"
            :start-placeholder="fun.getLanguageText($store.state.language, '开始日期')" style="margin-left: 20px"
            type="daterange" unlink-panels @change="change_data">
          </el-date-picker>
          <el-button style="margin-left: 8px;" @click="deleteData">删除</el-button>
        </div>
        <el-table :data="data_list" :max-height="$store.state.clientHeight - 100" stripe style="width: 100%">
          <el-table-column :label="fun.getLanguageText($store.state.language, '序号')" prop="id" width="120">
          </el-table-column>
          <el-table-column :label="fun.getLanguageText($store.state.language, '发生时间')" prop="up_time" width="200">
          </el-table-column>
          <el-table-column :label="fun.getLanguageText($store.state.language, '记录时间')" prop="db_time" width="200">
          </el-table-column>
          <el-table-column :label="fun.getLanguageText($store.state.language, '设备名称')" prop="deviceName" width="160">
          </el-table-column>
          <el-table-column label="IMEI" prop="imei" width="160">
          </el-table-column>
          <el-table-column prop="msg" label="log">
            <template slot-scope="scope">
              <span>{{ fun.getLanguageText($store.state.language, scope.row.msg) }}</span>
            </template>
          </el-table-column>
        </el-table>
        <el-pagination :current-page="data_page.currentPage" :page-size="data_page.pageSize"
          :page-sizes="data_page.pageSizes" :total="data_page.total" background class="footer"
          layout="sizes, prev, pager, next, jumper, total" @size-change="sizeChangeData"
          @current-change="currentChangeData">
        </el-pagination>
        <el-button style="position: absolute; right: 0 ;top:  0; z-index: 999" @click="download_uu('data')">{{
          fun.getLanguageText($store.state.language, '下载数据日志') }}
        </el-button>
      </el-tab-pane>
      <el-tab-pane :label="fun.getLanguageText($store.state.language, '安装信息')" name="400">
        <el-form :disabled="!install_edit" label-width="220px">
          <el-form-item :label="fun.getLanguageText($store.state.language, '化料品牌')">
            <el-input v-model="installInfo.brand" maxlength="16"
              :placeholder="fun.getLanguageText($store.state.language, '请输入')" style="margin-left: 20px"></el-input>
          </el-form-item>
          <el-form-item :label="fun.getLanguageText($store.state.language, '客户名称')">
            <el-input v-model="installInfo.customer" maxlength="16"
              :placeholder="fun.getLanguageText($store.state.language, '请输入')" style="margin-left: 20px"></el-input>
          </el-form-item>
          <el-form-item :label="fun.getLanguageText($store.state.language, '安装时间')">
            <el-input v-model="installInfo.installTime" maxlength="16"
              :placeholder="fun.getLanguageText($store.state.language, '请输入')" style="margin-left: 20px"></el-input>
          </el-form-item>
          <el-form-item :label="fun.getLanguageText($store.state.language, '洗衣龙型号规格')">
            <el-input v-model="installInfo.model" maxlength="16"
              :placeholder="fun.getLanguageText($store.state.language, '请输入')" style="margin-left: 20px"></el-input>
          </el-form-item>
          <el-form-item :label="fun.getLanguageText($store.state.language, '配方数量')">
            <el-input v-model="installInfo.recipeCount" maxlength="16"
              :placeholder="fun.getLanguageText($store.state.language, '请输入')" style="margin-left: 20px"></el-input>
          </el-form-item>
          <el-form-item :label="fun.getLanguageText($store.state.language, '化料数量')">
            <el-input v-model="installInfo.medicamentCount" maxlength="16"
              :placeholder="fun.getLanguageText($store.state.language, '请输入')" style="margin-left: 20px"></el-input>
          </el-form-item>
        </el-form>
        <el-form label-width="160px">
          <el-form-item :label="fun.getLanguageText($store.state.language, '操作')">
            <el-button :type="install_edit ? 'info' : 'primary'" style="margin-left: 20px;width: 100px"
              @click="install_edit = !install_edit">
              {{ fun.getLanguageText($store.state.language, install_edit ? '取消' : '编辑') }}
            </el-button>
            <el-button v-if="!install_edit" style="margin-left: 40px;width: 100px" type="primary"
              @click="get_install_info">{{ fun.getLanguageText($store.state.language, '读取') }}
            </el-button>
            <el-button v-else style="margin-left: 40px;width: 100px" type="primary" @click="set_install_info">{{
              fun.getLanguageText($store.state.language, '修改') }}
            </el-button>
          </el-form-item>
        </el-form>
      </el-tab-pane>
      <el-tab-pane :label="fun.getLanguageText($store.state.language, '系统信息')" name="500">
        <el-form :disabled="!system_edit" label-width="160px">
          <el-form-item :label="fun.getLanguageText($store.state.language, '产品名称')">
            <el-input v-model="systemInfo.name" maxlength="16"
              :placeholder="fun.getLanguageText($store.state.language, '请输入')" style="margin-left: 20px"></el-input>
          </el-form-item>
          <el-form-item :label="fun.getLanguageText($store.state.language, '产品型号')">
            <el-input v-model="systemInfo.model" maxlength="16"
              :placeholder="fun.getLanguageText($store.state.language, '请输入')" style="margin-left: 20px"></el-input>
          </el-form-item>
          <el-form-item :label="fun.getLanguageText($store.state.language, '产品序列号')">
            <el-input v-model="systemInfo.sn" maxlength="16"
              :placeholder="fun.getLanguageText($store.state.language, '请输入')" style="margin-left: 20px"></el-input>
          </el-form-item>
          <el-form-item :label="fun.getLanguageText($store.state.language, '硬件版本')">
            <el-input v-model="systemInfo.hVersion" maxlength="16"
              :placeholder="fun.getLanguageText($store.state.language, '请输入')" style="margin-left: 20px"></el-input>
          </el-form-item>
          <el-form-item :label="fun.getLanguageText($store.state.language, '固件版本')">
            <el-input v-model="systemInfo.sVersion" maxlength="16"
              :placeholder="fun.getLanguageText($store.state.language, '请输入')" style="margin-left: 20px"></el-input>
          </el-form-item>
        </el-form>
        <el-form label-width="160px">
          <el-form-item :label="fun.getLanguageText($store.state.language, '操作')">
            <el-button :type="system_edit ? 'info' : 'primary'" style="margin-left: 20px;width: 100px"
              @click="system_edit = !system_edit">
              {{ fun.getLanguageText($store.state.language, system_edit ? '取消' : '编辑') }}
            </el-button>
            <el-button v-if="!system_edit" style="margin-left: 40px;width: 100px" type="primary"
              @click="get_system_info">{{ fun.getLanguageText($store.state.language, '读取') }}
            </el-button>
            <el-button v-else style="margin-left: 40px;width: 100px" type="primary" @click="set_system_info">{{
              fun.getLanguageText($store.state.language, '修改') }}
            </el-button>
          </el-form-item>
        </el-form>
      </el-tab-pane>
    </el-tabs>
    <el-dialog :before-close="handleClose" :visible.sync="dialogVisible"
      :title="fun.getLanguageText($store.state.language, 'excel导出表单下载地址')" width="30%">
      <a :href="download_url" style="width: 100%;display: block;height: 30px;color: #1A74D2">{{ download_url }}</a>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">{{ fun.getLanguageText($store.state.language, '取消') }}</el-button>
        <el-button type="primary" @click="dialogVisible = false">{{ fun.getLanguageText($store.state.language, '确定')
        }}</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
export default {
  data() {
    return {
      // 系统操作编辑状态
      system_edit: false,
      // 系统信息info
      systemInfo: {
        name: "",
        model: "",
        sn: "",
        hVersion: "",
        sVersion: ""
      },
      // 安装操作编辑状态
      install_edit: false,
      // 安装信息info
      installInfo: {
        brand: "",
        recipeCount: "",
        medicamentCount: "",
        installTime: "",
        model: "",
        customer: ""
      },
      dialogVisible: false, // log窗口判断设定
      download_url: '', // 下载地址
      pickerOptions: { // 限制时间不让选择今天以前的
        disabledDate(time) {
          return time.getTime() > Date.now()
        }
      },
      time: '',
      time_warn: '',
      time_data: '',
      activeName: "first",
      router: false,
      input: '',
      user_type: '',
      tableData: [],
      sn: '',
      stat: {},
      loading: false,
      now_stat_key: '',
      now_stat_val: "",
      timer_load: 0,
      timer_stat: 0,
      timer_date: 0,
      date: {
        hours: 0,
        minutes: 0,
        seconds: 0
      },
      warn_list: [],
      warn_page: {
        total: 0, // 总条数
        currentPage: 1, // 当前页数
        totalPage: 0, // 总页数
        pageSize: 20, //
        pageSizes: [10, 20, 30, 50]
      },
      warn_info: {
        start: '',
        end: '',
        number: '',
        type: ''
      },
      data_list: [],
      data_page: {
        total: 0, // 总条数
        currentPage: 1, // 当前页数
        totalPage: 0, // 总页数
        pageSize: 20, //
        pageSizes: [10, 20, 30, 50]
      },
      data_info: {
        start: '',
        end: '',
        number: '',
        type: '',
        channel: ""
      },
      // 洗衣仓状态4个
      channel_state: [],
      // 远程状态
      remote_stat: -1,
      // wifi强度
      wifi: 0
    }
  },
  watch: {
    warn_info: {
      handler(newVal, oldVal) {
        console.log('执行更新')
        this.getWarnList()
      },
      deep: true
    },
    data_info: {
      handler(newVal, oldVal) {
        console.log('执行更新')
        this.getDataList()
      },
      deep: true
    },
    loading(val) {
      if (!val) {
        this.now_stat_key = ""
        this.now_stat_val = ""
      }
    },
    activeName(val) {
      this.getListHeight()
      if (val === 'second') {
        this.getWarnList()
      } else if (val === 'third') {
        this.getDataList()
      } else if (val === "400") {
        this.get_install_info()
      } else if (val === "500") {
        this.get_system_info()
      }
    }
  },
  methods: {
    // 删除数据
    deleteData(){
      this.$confirm(this.fun.getLanguageText(this.$store.state.language, '此操作将永久删除该数据, 是否继续?'),
        this.fun.getLanguageText(this.$store.state.language, '提示'), {
				confirmButtonText: this.fun.getLanguageText(this.$store.state.language, '确定'),
				cancelButtonText: this.fun.getLanguageText(this.$store.state.language, '取消'),
          type: 'warning'
        }).then(async () => {
          let res = await this.$http.post("data/delete", {
        sn: this.$route.query.sn
      })
      res = res.data
      if (res.code !== 2000) {
        this.$message.error(this.$store.state.language==1?"删除失败":"fail")
      }
      this.$message.success(  this.fun.getLanguageText(
          this.$store.state.language,
          "删除成功!"
        ))
        })
    },
    // 同步更新面板运行状态
    getRunType(val) {
      let res = "无"
      let { stat } = this
      if (val === 1) {
        if (stat.L_A1 === '1') {
          res = "冲气"
        }
        if (stat.L_W1 === '1') {
          res = "冲水"
        }
        if (stat.L_C1 === '1' || stat.L_C2 === '1' || stat.L_C3 === '1') {
          res = "加药中"
        }
      }
      if (val === 2) {
        if (stat.L_A2 === '1') {
          res = "冲气"
        }
        if (stat.L_W2 === '1') {
          res = "冲水"
        }
        if (stat.L_C4 === '1' || stat.L_C5 === '1' || stat.L_C6 === '1') {
          res = "加药中"
        }
      }
      if (val === 3) {
        if (stat.L_A3 === '1') {
          res = "冲气"
        }
        if (stat.L_W3 === '1') {
          res = "冲水"
        }
        if (stat.L_C7 === '1' || stat.L_C8 === '1') {
          res = "加药中"
        }
      }
      if (val === 4) {
        if (stat.L_A4 === '1') {
          res = "冲气"
        }
        if (stat.L_W4 === '1') {
          res = "冲水"
        }
        if (stat.L_C9 === '1' || stat.L_C10 === '1') {
          res = "加药中"
        }
      }
      return res
    },

    // 读取信号状态
    async get_signal() {
      let res = await this.$http.post("device/signal", {
        sn: this.$route.query.sn
      })
      res = res.data
      if (res.code === 2000) {
        console.log(res.data.signal)
        this.wifi = res.data.signal
      }
    },

    // 读取当前远程状态
    async get_remote_stat() {
      console.log("start----get_remote_stat")
      let res = await this.$http.post("device/remote-stat", {
        sn: this.$route.query.sn
      })
      res = res.data
      if (res.code === 2000) {

        if (res.data.stat === "") {
          this.remote_stat = -1
        } else {
          this.remote_stat = res.data.stat
        }
      }
      console.log("end----get_remote_stat")
    },
    // 获取当前设备仓状态
    async get_channel_state() {
      let res = await this.$http.post('device/channel-state', {
        sn: this.$route.query.sn
      })
      res = res.data
      if (res.code !== 2000) {
        // this.$message.error(res.msg);
        this.$message.error(this.fun.getLanguageText(this.$store.state.language,res.msg))

        return
      }
      this.channel_state = res.data
      console.log('log', res.data)
    },
    // 跳转设备参数/设备报表
    gotoView(view) {
      if (view === "1") {
        this.$router.push({
          name: "Home/Device/SetDevice",
          query: {
            sn: this.$route.query.sn
          }
        })
      } else if (view === "2") {
        this.$router.push({
          name: "Home/Report/ReportForm",
          query: {
            sn: this.$route.query.sn
          }
        })
      }
    },
    // 设置安装信息
    async set_system_info() {
      const loading = this.$loading({
        lock: true,
        text: 'Loading',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });
      this.systemInfo.sn = this.sn
      let res = await this.$http.post('device/w-system', this.systemInfo)
      res = res.data
      loading.close()
      if (res.code !== 2000) {
        // this.$message.error(res.msg);
        this.$message.error(this.fun.getLanguageText(this.$store.state.language,res.msg))

        console.log('写入异常')
        return;
      }
      this.$message({
        message: this.fun.getLanguageText(this.$store.state.language,"设置系统信息成功"),
        type: 'success'
      });
      this.get_system_info()
    },
    // 读取安装信息->installInfo
    async get_system_info() {
      let res = await this.$http.post('device/system-info', {
        sn: this.sn,
      })
      res = res.data
      if (res.code !== 2000) {
        this.$message.error(this.fun.getLanguageText(this.$store.state.language,res.msg))
        console.log('读取异常', res)
        return;
      }
      this.systemInfo = res.data
    },
    // 设置安装信息
    async set_install_info() {
      const loading = this.$loading({
        lock: true,
        text: 'Loading',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });
      let obj = JSON.parse(JSON.stringify(this.installInfo))
      obj.sn = this.sn
      let res = await this.$http.post('device/w-install', obj)
      res = res.data
      loading.close()
      if (res.code !== 2000) {
        this.$message.error(this.fun.getLanguageText(this.$store.state.language,res.msg))
        console.log('写入异常')
        return;
      }
      this.$message({
        message: this.fun.getLanguageText(this.$store.state.language,"设置安装信息成功"),
        type: 'success'
      });
      this.get_install_info()
    },
    // 读取安装信息->installInfo
    async get_install_info() {
      let res = await this.$http.post('device/install-info', {
        sn: this.sn,
      })
      res = res.data
      if (res.code !== 2000) {
        this.$message.error(this.fun.getLanguageText(this.$store.state.language,res.msg))
        console.log('读取异常', res)
        return;
      }
      this.installInfo = res.data
    },
    // 监听数据
    handleClose(done) {
      this.$confirm(this.fun.getLanguageText(this.$store.state.language, '确认关闭？'))
        .then(_ => {
          done();
        })
        .catch(_ => {
        });
    },
    // 下载数据
    async download_uu(e) {
      let ret
      if (e === 'data') {
        ret = await this.$http.post('data/excel', {
          sn: this.sn,
          start: this.data_info.start,
          end: this.data_info.end,
          type: this.data_info.type,
          number: this.data_info.number,
          channel: this.data_info.channel
        })
        console.log(ret)
      } else if (e === 'warn') {
        ret = await this.$http.post('warn/excel', {
          sn: this.sn,
          start: this.warn_info.start,
          end: this.warn_info.end,
          type: this.warn_info.type,
          number: this.warn_info.number
        })
        console.log(ret)
      }
      this.download_url = ''
      if (ret.data.code === 2000) {// 读取数据成功
        this.dialogVisible = true
        this.download_url = ret.data.data
      }
      console.log(e)
    },
    // 时间被改变____担心
    change_warn(e) {
      this.warn_info.start = parseInt((e[0]).valueOf() / 1000)
      this.warn_info.end = parseInt((e[1]).valueOf() / 1000)
    },
    // 时间被改变____数据
    change_data(e) {
      this.data_info.start = parseInt((e[0]).valueOf() / 1000)
      this.data_info.end = parseInt((e[1]).valueOf() / 1000)
    },
    // 远程使用权限设置
    async remote(e) {
      const loading = this.$loading({
        lock: true,
        text: 'Loading',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });
      let ret = await this.$http.post('device/remote', {
        sn: this.sn,
        stat: e
      })

      if (ret.data.code == 200) {
        // await this.get_stat()
        loading.close();
        if (e == 0) {
          this.$message({
            message: this.fun.getLanguageText(this.$store.state.language, '下发指令:远程控制关闭--成功'),
            type: 'success'
          });
        } else {
          this.$message({
            message: this.fun.getLanguageText(this.$store.state.language, '下发指令:远程控制开启--成功'),
            type: 'success'
          });
        }
        return
      }
      loading.close();
      let msg = ""
      if(e == 0){
        msg = this.fun.getLanguageText(this.$store.state.language, '下发指令:远程控制关闭--失败')
      } else {
        msg = this.fun.getLanguageText(this.$store.state.language, '下发指令:远程控制开启--失败')
      }
      this.$message.error(msg)
      console.log(ret)
    },
    async set_stat(e, val) {
      return
      let data = {
        sn: this.sn
      }
      data[e] = val
      this.loading = true
      let ret = await this.$http.post('device/light-button', data)
      this.loading = false
      console.log(ret)
      this.now_stat_key = e
      this.now_stat_val = val
      if (ret.data.code != 200) {
        this.$message({
          message: '下发指令:修改[' + this.now_stat_key + ']状态失败',
          type: 'warning'
        })
        return
      }
      this.$message({
        message: '下发指令:修改[' + this.now_stat_key + ']状态成功',
        type: 'success'
      })

      /*
      this.timer_load = setTimeout(() => {
        this.$message({
          message: '下发指令:修改[' + this.now_stat_key + ']状态失败',
          type: 'warning'
        })
        this.loading = false
      }, 10000)
      */
    },
    // get
    async get_stat() {
      let ret = await this.$http.post('device/get-stat', {
        sn: this.sn
      })
      if (ret.data.code === 2000) {
        this.stat = ret.data.data
        console.log(this.stat)
        /*
        console.log(this.stat)
        let key = this.now_stat_key
        let val = this.now_stat_val
        if (this.stat[key] == val && key && val) {
          this.loading = false
          clearTimeout(this.timer_load)
          this.$message({
            message: '下发指令:修改[' + this.now_stat_key + ']状态成功',
            type: 'success'
          });
        }
        */
      }
    },
    // 计算高度
    getListHeight() {
      let Html = document.getElementsByClassName('box-card')
      if (Html.length > 0) {
        this.$store.state.clientHeight = Html[0].clientHeight - 131

      }
    },
    // 获取报警日志
    async getWarnList() {
      let ret = await this.$http.post('warn/list', {
        sn: this.sn,
        currentPage: this.warn_page.currentPage,
        pageSize: this.warn_page.pageSize,
        start: this.warn_info.start,
        end: this.warn_info.end,
        type: this.warn_info.type,
        number: this.warn_info.number
      })
      if (ret.data.code === 2000) {
        this.warn_list = ret.data.data.info
        console.log(ret.data.data,)
        this.warn_page.total = Number(ret.data.data.page.total)
      }

    },
    // 改变页码
    currentChangeWarn(e) {
      this.warn_page.currentPage = e
      this.getWarnList()
    },
    // 选择页码
    sizeChangeWarn(e) {
      this.warn_page.pageSize = e
      this.getWarnList()
    },
    // 获取数据日志
    async getDataList() {
      let ret = await this.$http.post('data/list', {
        sn: this.sn,
        currentPage: this.data_page.currentPage,
        pageSize: this.data_page.pageSize,
        start: this.data_info.start,
        end: this.data_info.end,
        type: this.data_info.type,
        number: this.data_info.number,
        channel: this.data_info.channel
      })
      if (ret.data.code === 2000) {
        this.data_list = ret.data.data.info
        console.log(ret.data.data,)
        this.data_page.total = Number(ret.data.data.page.total)
      }

    },
    // 改变页码
    currentChangeData(e) {
      this.data_page.currentPage = e
      this.getDataList()
    },
    // 选择页码
    sizeChangeData(e) {
      this.data_page.pageSize = e
      this.getDataList()
    },
  },
  mounted() {
    this.getListHeight()
    window.onresize = () => {
      return (() => {
        this.getListHeight()
      })();
    }
  },
  destroyed() {
    window.onresize = null;
    clearInterval(this.timer_stat)
    clearInterval(this.timer_date)
    clearTimeout(this.timer_load)
  },
  created() {
    console.log(this.$route.query.sn)
    this.sn = this.$route.query.sn

    const end = new Date();
    const start = new Date();
    start.setTime(start.getTime() - 3600 * 1000 * 24 * 10);
    this.time_data = [start, end]
    this.data_info.start = parseInt((start).valueOf() / 1000)
    this.data_info.end = parseInt((end).valueOf() / 1000)

    this.time_warn = [start, end]
    this.warn_info.start = parseInt((start).valueOf() / 1000)
    this.warn_info.end = parseInt((end).valueOf() / 1000)

    this.get_stat()
    this.get_remote_stat()
    this.get_signal()
    this.get_channel_state()
    this.timer_stat = setInterval(() => {
      this.get_stat()
      this.get_remote_stat()
      this.get_signal()
      this.get_channel_state()
    }, 5000)
    /*    this.timer_date = setInterval(() => {
          let myDate = new Date(); //实例一个时间对象
          this.date.hours = myDate.getHours(); //获取系统时，
          this.date.minutes = (Array(2).join(0) + myDate.getMinutes()).slice(-2); //分
          this.date.seconds = (Array(2).join(0) + myDate.getSeconds()).slice(-2); //秒
        }, 400)*/


  }
};
</script>

<style lang="less" scoped>
.tabs {
  position: relative;

  .dow-button {
    position: absolute;
    right: 0;
    top: -90px;
    z-index: 999;
  }
}

.el-input {
  width: 240px;
  margin-right: 10px;
}

.el-select {
  width: 180px;
  margin-left: 20px;
}

.container {
  display: flex;
  flex-direction: column;
}

.top {
  // height: 32px;
  margin-bottom: 20px;
  position: relative;
}

.add-username {
  position: absolute;
  right: 0;
}

.footer {
  display: flex;
  padding: 0;
  margin-top: 14px;
  justify-content: flex-end;
  margin-bottom: 2px;
}

.state-item {
  width: 280px;
  height: 350px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  font-size: 14px;
  border: 1px solid #BBBBBB;

  .item-title {

    width: 100%;
    height: 54px;
    background-color: #E1F0F9;
    color: #000000;
    font-size: 16px;
    text-align: center;
    line-height: 54px;

  }

  .item-1 {
    padding-left: 20px;
    padding-right: 20px;
    width: 100%;
    // text-align: center;
    margin-bottom: 24px;
  }

  .item-3 {
    width: 33.333%;
    height: 40px;
    text-align: center;
    word-wrap: break-word;
    // line-height: 40px;
  }

  .item-2 {
    box-sizing: border-box;
    width: 100%;
    height: 34px;
    // text-align: center;
    line-height: 34px;
    padding-left: 20px;
    border: 1px solid rgba(240, 240, 240, 100);
  }
}

.showImg {
  // width: 100%;
  // height: 100%;
  background-color: #F5F5F5;
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  div {
    font-size: 12px;
  }
}

.ben-btn {
  width: 100px;
  height: 40px;
  margin-right: 30px;
  background-image: url(../../assets/btn_normal.png);
  background-repeat: no-repeat;
  // flex: 1;
  background-size: 100% 100%;
  -moz-background-size: 100% 100%;
  color: #FFFFFF;
  line-height: 40px;
  text-align: center;
  font-size: 18px;
}

.img {
  display: inline-block;
  width: 76px;
  height: 76px;
  background-image: url(../../assets/close_on.png);
  background-repeat: no-repeat;
  background-size: 100% 100%;
  -moz-background-size: 100% 100%;
  color: #FFFFFF;
  line-height: 76px;
  text-align: center;
  font-size: 22px;
}

.time {
  align-items: center;

  div {
    width: 100px;
    height: 40px;
    text-align: center;
    line-height: 40px;
    font-size: 18px;
    background: #DFE3E5;
  }

  span {
    width: 24px;
    height: 24px;
    background: #1A74D2;
    font-size: 16px;
    color: #FFFFFF;
    line-height: 24px;
    text-align: center;
  }

  p {
    font-size: 24px;
    width: 12px;
    height: 24px;
    line-height: 24px;
    text-align: center;
    color: #1382F6;
  }

}

.btn-close {
  width: 128px;
  height: 30px;
}

.btn-open {
  width: 128px;
  height: 30px;
  background-image: url('/assets/img1/btn-up.png');
  background-size: 128px 30px;
  background-repeat: no-repeat;
}
</style>
